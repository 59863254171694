import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Achievement = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="py-12 font-titleFont w-full grid grid-cols-1 lgl:grid lgl:grid-cols-2 gap-10 lgl:gap-20"
    >
      <div>
        <div className="py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">1991 - 2023</p>
          <h2 className="text-4xl font-bold">
            Local and National Honors and Awards
          </h2>
        </div>
        <div className="mt-14 w-full h-[2000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Lifetime Achievement Award by the Emerge Club"
            subTitle="University of Nigeria, Nsukka (2023)"
            result="Nsukka"
            des="This award honored her career-long contributions to academia and her legacy at the University of Nigeria, Nsukka.
"
          />
          <ResumeCard
            title="TETFund Book Publication Grant"
            subTitle="(2019)"
            result="Nsukka"
            des="This grant from the Tertiary Education Trust Fund facilitated the publication of one of her major works, supporting her contribution to academic literature in Nigeria."
          />
          <ResumeCard
            title="University of Nigeria Most Punctual Lecturer Award"
            subTitle="University of Nigeria, Nsukka (2016)"
            result="Nsukka"
            des="Reflecting her dedication and professionalism, this award recognized her exemplary punctuality and commitment to her teaching responsibilities."
          />
          <ResumeCard
            title="Vice Chancellor’s Prize for Best Graduating Ph.D. Candidate"
            subTitle="University of Nigeria, Nsukka (2001 - 2002)"
            result="Nsukka"
            des="Professor Uchendu received this award (second position) as one of the best-performing PhD candidates in the University of Nigeria."
          />
          <ResumeCard
            title="University of Nigeria Postgraduate Research Awards"
            subTitle="University of Nigeria, Nsukka (1999 - 2001)"
            result="Nsukka"
            des="These awards supported her PhD research and recognized her outstanding academic performance during her postgraduate studies."
          />
          <ResumeCard
            title="Federal Government of Nigeria Scholarship"
            subTitle="(1991–1992)"
            result="Nsukka"
            des="Awarded for her M.A. studies, this scholarship acknowledged her academic excellence and supported her graduate education.
"
          />
        </div>
      </div>
      <div>
        <div className="py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2001 - 2020</p>
          <h2 className="text-4xl font-bold">
            International Honors and Awards
          </h2>
        </div>
        <div className="mt-14 w-full h-[3000px] md:h-[2500px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="African Studies and Research Forum (ASRF) Runner-Up Best Book Award "
            subTitle="ASRF (2020)"
            result="Africa"
            des="Her book Islam in the Niger Delta, 1890-2017 was recognized as one of the best scholarly works, showcasing her expertise in Islamic history in Nigeria."
          />
          <ResumeCard
            title="Humboldt Alumni Award for Innovative Networking Initiative"
            subTitle="(2022)"
            result="Germany"
            des="This award funded her #Don’t-Litter-Initiative at UNN, a project focused on responsible waste management through community involvement and sustainability education."
          />
          <ResumeCard
            title="Alexander von Humboldt Book and Equipment Grants"
            subTitle="AvH (2009, 2018)"
            result="Germany"
            des="These grants from the AvH Foundation supported her research infrastructure and publication efforts, reinforcing her scholarship in Nigerian and African studies."
          />
          <ResumeCard
            title="The University of Texas at Austin’s Wangari Maathai Award for Innovative Scholarship and Leadership"
            subTitle="The University of Texas at Austin (2017)"
            result="USA"
            des="This prestigious award honored her innovative research and leadership in gender studies, named after the Nobel Laureate Wangari Maathai."
          />
          <ResumeCard
            title="Alexander von Humboldt (AvH) Postdoctoral Fellowship"
            subTitle="Zentrum Moderner Orient, Berlin (2006–2008)"
            result="Germany"
            des="Funded by the AvH Foundation, this fellowship enabled her research in Berlin and established her international collaborations in gender and social history."
          />
          <ResumeCard
            title="Advanced Research Fellowship by CODESRIA"
            subTitle="Senegal (2005–2006)"
            result="Senegal"
            des="This fellowship at the Council for the Development of Social Science Research in Africa (CODESRIA) supported her pioneering work on masculinities and gender studies in Africa."
          />
          <ResumeCard
            title="Postdoctoral Research Fellowship"
            subTitle="SOAS, University of London (2004)"
            result="England"
            des="Uchendu conducted postdoctoral research at the School of Oriental and African Studies (SOAS), deepening her expertise in African studies and history."
          />
          <ResumeCard
            title="Fulbright Visiting Research Award"
            subTitle="University of Kansas, USA (2001–2002)"
            result="USA"
            des="This prestigious Fulbright grant supported her research in women’s studies, allowing her to expand her scholarship at the University of Kansas."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Achievement;
