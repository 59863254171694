import { AiFillAppstore } from "react-icons/ai";
import { FaMobile, FaGlobe } from "react-icons/fa";
import { SiProgress, SiAntdesign } from "react-icons/si";

// Features Data
export const featuresData = [
  {
    id: 1,
    title: "Professional Editing",
    des: "Editor of History in Africa: A Journal of Debates, Methods, and Source Analysis for the African Studies Association (2019–2025).",
  },
  {
    id: 2,
    icon: <AiFillAppstore />,
    title: "Mentorship and Development",
    des: "Established programs like the Building Research Competence Project and the Faculty of Arts Capacity Building Workshop at UNN to develop research skills among students and early-career academics.",
  },
  {
    id: 3,
    icon: <SiProgress />,
    title: "Conference Organization",
    des: "Organized conferences on pressing topics, such as Nigeria’s resource conflicts, Islamic propagation, and technological advancement.",
  },
  {
    id: 4,
    icon: <FaMobile />,
    title: "Community Engagement",
    des: "Launched the #Don’t-Litter-Initiative for sustainable waste management at the University of Nigeria Nsukka campus.",
  },
  {
    id: 5,
    icon: <SiAntdesign />,
    title: "Policy Advisory and Consultancy",
    des: "Served on the Presidential Committee for Nigeria’s National Defence Policy and as a consultant to Transparency International on security sector involvement in Nigerian elections.",
  },
  {
    id: 6,
    icon: <FaGlobe />,
    title: "Research and Publications",
    des: "Authored multiple books and articles on topics such as Islam in Nigeria, women’s roles in conflict, and the Nigerian Civil War.",
  },
];
