import React, { useState } from "react";
import { Link } from "react-scroll";
import { FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { logo } from "../../assets/index";
import { bannerimg2 } from "../../assets/index";
import { navLinksdata } from "../../constants";
import { SiResearchgate } from "react-icons/si";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="w-full h-24 sticky top-0 z-50 bg-bodyColor mx-auto flex justify-between items-center  font-titleFont border-b-[1px] border-b-gray-600">
      <div className="flex gap-3 items-center">
        <div className="bg-[#212428] rounded-full p-1 border-[5px] border-[#2d3236]  flex">
          <img
            src={bannerimg2}
            alt="logo"
            className="h-[55px] w-[55px] relative -bottom-1"
          />
        </div>
        <p className="font-titleFont font-bold">Egodi Uchendu</p>
      </div>
      <div>
        <ul className="hidden mdl:inline-flex items-center gap-6 lg:gap-10">
          {navLinksdata.map(({ _id, title, link }) => (
            <li
              className="text-base font-normal text-gray-400 tracking-wide cursor-pointer hover:text-designColor duration-300"
              key={_id}
            >
              <Link
                activeClass="active"
                to={link}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                {title}
              </Link>
            </li>
          ))}
        </ul>
        <span
          onClick={() => setShowMenu(!showMenu)}
          className="text-xl mdl:hidden bg-black w-10 h-10 inline-flex items-center justify-center rounded-full text-designColor cursor-pointer"
        >
          <FiMenu />
        </span>
        {showMenu && (
          <div className="w-[80%] h-screen overflow-scroll absolute top-0 left-0 bg-gray-900 p-4 scrollbar-hide">
            <div className="flex flex-col gap-8 py-2 relative">
              <div>
                <img className="w-20" src={bannerimg2} alt="logo" />
                <p className="text-sm text-gray-400 mt-4 text-justify">
                  Professor Egodi Uchendu, a distinguished scholar at the
                  University of Nigeria, Nsukka, is renowned for her
                  groundbreaking work in women’s history, masculinities, and
                  conflict studies in Eastern Nigeria, supported by prestigious
                  grants and accolades, and her influential roles in academic
                  and policy circles globally.
                </p>
              </div>
              <ul className="flex flex-col gap-4">
                {navLinksdata.map((item) => (
                  <li
                    key={item._id}
                    className="text-base font-normal text-gray-400 tracking-wide cursor-pointer hover:text-designColor duration-300"
                  >
                    <Link
                      onClick={() => setShowMenu(false)}
                      activeClass="active"
                      to={item.link}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="flex flex-col gap-4">
                <h2 className="text-base uppercase font-titleFont mb-4">
                  Find me on
                </h2>
                <div className="flex gap-4">
                  <a
                    href="https://www.facebook.com/ProfessorEgodiUchendu/"
                    target="_blank"
                    className="bannerIcon"
                  >
                    <FaFacebookF />
                  </a>
                  <a
                    href="https://de.wikipedia.org/wiki/Egodi_Uchendu"
                    target="_blank"
                    className="bannerIcon"
                  >
                    <SiResearchgate />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/egodi-uchendu-73a41818/"
                    target="_blank"
                    className="bannerIcon"
                  >
                    <FaLinkedinIn />
                  </a>
                </div>
              </div>
              <span
                onClick={() => setShowMenu(false)}
                className="absolute top-4 right-4 text-gray-400 hover:text-designColor duration-300 text-2xl cursor-pointer"
              >
                <MdClose />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
