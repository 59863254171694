import React from "react";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { contactImg } from "../../assets/index";
import { SiResearchgate } from "react-icons/si";

const ContactLeft = () => {
  return (
    <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center">
      <img
        className="w-full h-64 object-cover rounded-lg mb-2"
        src={contactImg}
        alt="contactImg"
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-white">Egodi Uchendu</h3>
        <p className="text-lg font-normal text-gray-400">Professor</p>
        <p className="text-base text-gray-400 tracking-wide">
          Professor Egodi Uchendu, a distinguished scholar at the University of
          Nigeria, Nsukka, is renowned for her groundbreaking work in women’s
          history, masculinities, and conflict studies in Eastern Nigeria,
          supported by prestigious grants and accolades, and her influential
          roles in academic and policy circles globally.{" "}
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Phone: <span className="text-lightText">+234 8039617898</span>
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Email:{" "}
          <span className="text-lightText">egodi.uchendu@unn.edu.ng</span>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-base uppercase font-titleFont mb-4">Find me on</h2>
        <div className="flex gap-4">
          <a
            href="https://www.facebook.com/ProfessorEgodiUchendu/"
            target="_blank"
            className="bannerIcon"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://de.wikipedia.org/wiki/Egodi_Uchendu"
            target="_blank"
            className="bannerIcon"
          >
            <SiResearchgate />
          </a>
          <a
            href="https://www.linkedin.com/in/egodi-uchendu-73a41818/"
            target="_blank"
            className="bannerIcon"
          >
            <FaLinkedinIn />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactLeft;
