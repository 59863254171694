import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full grid grid-cols-1 lgl:grid lgl:grid-cols-2 gap-10 lgl:gap-20"
    >
      {/* part one */}
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">1989 - 2019</p>
          <h2 className="text-3xl md:text-4xl font-bold">Education </h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="PhD in Social History/Women’s History"
            subTitle="University of Nigeria, Nsukka (2002)"
            result="Hons."
            des="Professor Uchendu completed her doctoral studies with a focus on Social History, specializing in women’s experiences, roles, and identities. Her research emphasized the historical evolution of women’s contributions and their impact on Nigerian society, reflecting her enduring interest in gender studies and conflict."
          />
          <ResumeCard
            title="M.A. in Political History"
            subTitle="University of Nigeria, Nsukka (1993)"
            result="Hons."
            des="In her master's program, Uchendu studied the dynamics of political history, particularly in Nigeria. Her studies during this time laid the groundwork for her later research on historical movements, political change, and gender in Nigeria."
          />
          <ResumeCard
            title="B.A. in History"
            subTitle="University of Nigeria, Nsukka (1989)"
            result="Hons."
            des="As an undergraduate, Uchendu pursued a comprehensive study of history, gaining foundational knowledge in Nigerian and African historical narratives, which would later serve as the basis for her specialized research on the Nigerian Civil War and Islamic history in Eastern Nigeria.
"
          />
        </div>
      </div>
      {/* part Two */}

      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor text-transparent tracking-[4px]">
            2010 - 2022
          </p>
          <h2 className="text-3xl md:text-4xl text-transparent font-bold">
            Job Experience
          </h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Diploma in Management of Higher Education Institutes"
            subTitle="Galilee International Management Institute, Nahalal (2019)"
            result="Israel"
            des="This diploma provided Prof. Uchendu with essential skills in educational management, equipping her to effectively lead academic departments and research centers and contribute to policy formulation in higher education."
          />
          <ResumeCard
            title="Certificate in English for Graduate Studies"
            subTitle=" Loyola University, New Orleans (2001)"
            result="USA"
            des="This certification facilitated Prof. Uchendu's proficiency in academic English, enabling her to communicate her research effectively within international academic circles."
          />
          <ResumeCard
            title="Certificate in Modern Higher Education Management and Leadership"
            subTitle="University of Applied Sciences, Osnabrück, Germany, and University of Addis Ababa, Ethiopia (2008)"
            result="Germany"
            des="Through this course, Prof. Uchendu developed advanced leadership skills tailored to higher education, preparing her for her roles in managing academic programs and faculty development initiatives."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Education;
