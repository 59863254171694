import React from "react";
import Title from "../layouts/Title";
// import { projectTwo, projectThree } from "../../assets/index";
import projectOne from "../../assets/images/projects/Women_and_Conflict_cover_2_14_07 copy_page-0001.jpg";
import projectTwo from "../../assets/images/projects/Dawn of islam.png";
import projectThree from "../../assets/images/projects/Islam in the Niger Delta-cover copy.jpg";
import projectFour from "../../assets/images/projects/Masculinities copy.jpeg";
import projectFive from "../../assets/images/projects/Nigeria's Resource Wars SS (1).png";
import projectSix from "../../assets/images/projects/UCHENDU and EDEAGU Negotiating Patriarchy and Gender in Africa_LITHO_Proof1-2_page-0001.jpg";
import projectSeven from "../../assets/images/projects/Witchcraft Cover.png";
import projectNine from "../../assets/images/projects/cov_9783110766431_Uchendu_RZ_bz_dpm_v2_page-0001.jpg";
import projectEight from "../../assets/images/projects/Book Cover-Economic History.png";
import ProjectsCard from "./ProjectsCard";

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="VISIT MY WIKIPEDIA FOR MORE INFORMATION"
          des="Publications"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Women and Conflict in the Nigerian Civil War (2007)"
          routing="https://www.researchgate.net/publication/272102474_Women_and_Conflict_in_the_Nigerian_Civil_War"
          des=" Examines the roles, experiences, and resilience of Nigerian women during the Nigerian Civil War, highlighting their unique contributions and struggles.
"
          src={projectOne}
        />
        <ProjectsCard
          title="Dawn for Islam in Eastern Nigeria: A History of the Arrival of Islam in Igboland (2011)
"
          routing="https://www.degruyter.com/document/doi/10.1515/olzg-2015-0054/pdf"
          objectFit={"contain"}
          des=" Traces the historical spread of Islam in Eastern Nigeria, particularly in Igboland, exploring its cultural and social impact on the region."
          src={projectTwo}
        />
        <ProjectsCard
          title="Islam in the Niger Delta, 1890-2017: A Synthesis of the Accounts of Indigenes and Migrants (2018)"
          objectFit={"contain"}
          routing="https://www.researchgate.net/publication/344794453_Islam_in_the_Niger_Delta_1890-2017_A_Synthesis_of_the_Accounts_of_Indigenes_and_Migrants"
          des="Provides an in-depth look at the historical evolution of Islam in Nigeria’s Niger Delta, detailing perspectives from both indigenous and migrant communities."
          src={projectThree}
        />
        <ProjectsCard
          title="Masculinities in Contemporary Africa (Ed., 2008)
"
          routing="https://www.researchgate.net/publication/272100118_Masculinities_in_Contemporary_Africa"
          objectFit={"contain"}
          des="A collection of essays analyzing diverse expressions of masculinity across African societies and their influence on gender relations and societal roles."
          src={projectFour}
        />
        <ProjectsCard
          title="Nigeria's Resource Wars (Ed., 2020)
"
          routing="https://www.researchgate.net/publication/345814605_Nigeria's_Resource_Wars"
          objectFit={"contain"}
          des="Explores the causes, dynamics, and socio-political consequences of resource-based conflicts within Nigeria, aiming to provide pathways to peace and resource equity."
          src={projectFive}
        />
        <ProjectsCard
          title="Negotiating Patriarchy and Gender in Africa: Discourses, Practices, and Policies (Ed., 2021, 2023)"
          des="Investigates the ongoing challenges and adaptations surrounding patriarchy and gender roles in African societies through both historical and contemporary lenses."
          routing="https://rowman.com/ISBN/9781793642066/Negotiating-Patriarchy-and-Gender-in-Africa-Discourses-Practices-and-Policies"
          src={projectSix}
          objectFit={"contain"}
        />
        <ProjectsCard
          title="Witchcraft in Africa: Meanings, Factors, and Practices (Ed., 2023)
"
          routing="https://www.cambridgescholars.com/product/978-1-5275-9026-7"
          objectFit={"contain"}
          des="Delves into the complex beliefs, societal reactions, and human rights issues associated with witchcraft across African communities."
          src={projectSeven}
        />
        <ProjectsCard
          title="Economic Development of Nigeria Since 1800 (Ed., 2024)
"
          routing="https://www.elivapress.com/en/book/book-4794938956/"
          des="Chronicles the economic history of Nigeria, examining key developments, challenges, and transformations that have shaped the nation’s economy over two centuries."
          src={projectEight}
          objectFit={true}
        />
        <ProjectsCard
          title="Nigeria's 2019 Democratic Experience 
"
          routing="https://www.degruyter.com/document/doi/10.1515/9783110766561/html?lang=en"
          des="Nigeria's democracy since independence has faced challenges, with electoral violence by political elites undermining stability and prompting military coups. *Nigeria's 2019 Democratic Experience* examines these issues in depth."
          objectFit={true}
          src={projectNine}
        />
      </div>
    </section>
  );
};

export default Projects;
