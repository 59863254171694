import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:grid lgl:grid-cols-2 gap-10 lgl:gap-20"
    >
      <div>
        <div className="py-6 lgl:py-12 flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">
            2001 - Present
          </p>
          <h2 className="text-4xl font-bold">Professional Work Experience</h2>
        </div>
        <div className="mt-14 w-full h-[1400px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Director, Professor B. I. C. Ijomah Centre for Policy Studies and Research"
            subTitle="University of Nigeria, Nsukka (2019 – 2021)
"
            result="Nigeria"
            des="Professor Uchendu established and structured this center from the ground up, overseeing office spaces, staff recruitment, and program development. She launched the annual Dr. Emmanuel Uduaghan Public Lecture on Youth Leadership and initiated conferences, workshops, and journals like the Journal of Public Policy and the Journal of Behavioral Sciences."
          />
          <ResumeCard
            title="Head, Department of History & International Studies"
            subTitle="University of Nigeria, Nsukka (2012-2013)"
            result="Nigeria"
            des="In this leadership role, Professor Uchendu managed departmental staff and students, introduced foreign scholars to the department, and enhanced postgraduate program structures. She promoted professional development by encouraging students’ involvement in conferences, regularized program durations, and helped clear postgraduate backlogs."
          />
          <ResumeCard
            title="Director, Gender Institute"
            subTitle="Council for the Development of Social Science Research in Africa (CODESRIA), Dakar
(2005)"
            result="Senegal"
            des="Professor Uchendu directed the Gender Institute on the theme of “Masculinities in Contemporary Africa,” managing the annual program attended by researchers and scholars from across Africa. She produced publications from the institute, contributing significantly to the field of gender studies in Africa."
          />
          <ResumeCard
            title="Vice President"
            subTitle="Fulbright Alumni Association of Nigeria (2010 – 2014)"
            result="Nigeria"
            des="Professor Uchendu served two terms as Vice President, supporting alumni activities, facilitating workshops, and strengthening the association’s networks.

"
          />
        </div>
      </div>
      <div>
        <div className="py-6 lgl:py-12 flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px] text-transparent">
            2001 - 2020
          </p>
          <h2 className="text-4xl font-bold text-transparent">
            Trainer Experience
          </h2>
        </div>
        <div className="mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="President"
            subTitle="African Humanities Research and Development Circle (AHRDC) (2022- Present)"
            result="INT'L"
            des="As the founding president, Professor Uchendu transformed AHRDC from a research group into an international professional organization for Africanist scholars, launching the organization’s journal, Journal of African Humanities Research and Development."
          />
          <ResumeCard
            title="Spokesperson and Sub-Team Leader"
            subTitle="Presidential Committee for the Review of Nigeria’s National Defence Policy (2014 - 2015)"
            result="Nigeria"
            des="As a spokesperson, Professor Uchendu contributed to the development of Nigeria’s National Defence Policy, leading fact-finding missions and collaborating with various sectors to shape defense policy frameworks."
          />
          <ResumeCard
            title="Professor, Department of History & International Studies"
            subTitle="University of Calabar Sabbatical Appointment, (2016 – 2017)"
            result="Nigeria"
            des="During her sabbatical, she innovated the graduate program structure by integrating online teaching and hands-on historical fieldwork, providing students with critical experience in archival research."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Experience;
